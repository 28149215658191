import React, { useContext, useEffect, useState } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import Link from 'next/link';
import { useRouter } from 'next/router';
import ActiveLink from '../../ActiveLink';
import notificationTypes from '../../../constants/modalNotificationConst';
import { useDeliveryDetails, useZipCodeStatus } from '../../../hooks/app';
import CustomSlider from '../../CustomSlider';
import { isWishlistApplicable } from '../../../constants/feature';
import CartProductsWidget from '../../CartProductsWidget';
import { SCHEDULE_TYPE_ASAP, SCHEDULE_TYPE_FUTURE } from '../../../constants/scheduleType';
import { SCHEDULE } from '../../../constants/deliveryType';
import getRouterPath from '../../../helpers/getRouterPath';
import HappyHours from '../../HappyHours';
import UserDropdown from '../../UserDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { pushNotification } from '../../../redux/slices/modalSlice';
import { toggleLoginDrawer, toggleSidebar, toggleUserDropdown } from '../../../redux/slices/userSlice';
import { setAllCartProductsWidget } from '../../../redux/slices/cartSlice';
import HraderDeliveryAddress from '../bento/HraderDeliveryAddress';
import { useConfigData } from '../../../Context/ConfigProvider';

export default function SearchTabs({ fullCategoryList, defaultSearchTab, children, backPath, initialLocationAddress }) {
  const router = useRouter();
  const {
    route,
    query: { tab },
    pathname
  } = router;
  const [locationHref, setLocationHref] = useState('');
  const appConfig = useConfigData()
  const {
    data: { asapEnabled = true, happyHours }
  } = useDeliveryDetails(false, true);
  const { is_enable: is_happy_hours_enable, end_time } = happyHours || {};
  const { isLoggedIn, showUserDropdown, couponData, initialHistoryLength } = useSelector(state => state.user)
  const {
    cartData: { cart_items: cartItems = [], cart_item_count: cartItemCount = 0 } = {}
  } = useSelector(state => state.cart) || {}
  const dispatchRedux = useDispatch()

  const [showAllCartProductsWidget, setShowAllCartProductsWidget] = useState(false);
  const {
    data: { validZipcode }
  } = useZipCodeStatus(initialLocationAddress);

  const currentSlug = '';
  const showWishlistIcon = pathname !== '/login' && pathname !== '/checkout';

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setLocationHref(`${window.location.pathname}`);
  }, [currentSlug, route]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsVisible(scrollPosition > 200);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function handleSideMenuClick() {
    dispatchRedux(toggleSidebar())
  }
  function toggleCartWidget() {
    setShowAllCartProductsWidget(true);
    dispatchRedux(setAllCartProductsWidget(true))
  }

  function renderCartItemCount() {
    return (
      <ActiveLink
        href=""
        onClick={toggleCartWidget}
        className="cursor-pointer d-flex align-items-center cart-btn-info"
        otherAttributes={{ 'data-testid': '#header_cart_btn' }}
      >
        <span className="icon icon-cartdark-icon vertical-align-middle relative">
          {cartItemCount > 0 ? (
            <div className="item-add-count d-flex align-items-center justify-content-center">
              <span className="bold fs-12">{cartItemCount}</span>
            </div>
          ) : null}
        </span>
      </ActiveLink>
    );
  }
  function renderUserInfo() {
    return (
      <ActiveLink
        className="cursor-pointer user-info-btn d-flex align-items-center"
        onClick={() => {
          dispatchRedux(isLoggedIn ? toggleUserDropdown() : toggleLoginDrawer())
        }}
      >
        <span className="icon icon-account-h vertical-align-middle" />
      </ActiveLink>
    );
  }
  function renderWishlistIcon() {
    if (isWishlistApplicable && showWishlistIcon) {
      return (
        <a href="/wishlist/favorites">
          <button type="button" className="btn heart-btn">
            <i className="icon icon-heart-outline " />
          </button>
        </a>
      );
    }
  }
  function openSearchPopup() {
    router.push('/search');
  }
  const handleNavigate = link => {
    const url = new URL(link);
    return `${url.pathname}${tab && tab.length ? `?tab=${tab}` : ''}`;
  };

  function goToCart() {
    if (validZipcode) {
      let deliveryType = SCHEDULE_TYPE_ASAP;
      const page = getRouterPath(pathname);
      if (page === 'scheduledshop' || tab == SCHEDULE || !asapEnabled) {
        deliveryType = SCHEDULE_TYPE_FUTURE;
      }
      router.push({
        pathname: '/checkout',
        query: { deliveryType }
      });
    } else {
      dispatchRedux(pushNotification({ type: notificationTypes.deliverAddressPopup }))
    }
  }
  function goBack() {
    if (window.history.length > initialHistoryLength) {
      router.back();
    } else {
      router.push('/');
    }
  }
  function goHome() {
    router.push('/');
  }
  return (
    <CSSTransition in={isVisible} mountOnEnter timeout={300} classNames="search-tabs-control" unmountOnExit>
      <div className="search-tabs d-lg-none">
        <div className="wrapper">
          <div className="search-tabs-header">
            <div className='flex'>
              {backPath ? (
                <a className="btn left-btn" test-id="#icon-back" onClick={goBack}>
                  <span className="icon-back" />
                </a>
              ) : (
                <button type="button" className="btn left-btn" onClick={handleSideMenuClick} test-id="#hamburger_icon">
                  <span className="icon-hamburger icon-ham-menu" />
                </button>
              )}
              <div className='h-[56px] pl-[15px]'>

                <img
                  data-testid="#main-logo-img"
                  className="h-full yy"
                  src={appConfig.HEADER_LOGO_SHORT}
                  onClick={goHome}
                  alt={appConfig.APP_NAME}
                />
              </div>
            </div>
            {/* <div onClick={openSearchPopup} className="search-section">
              <p className="w-100 d-flex align-items-center mb-0">Search</p>
              <i className="icon-search" />
            </div> */}

            <div className="search-action-btns">
              <button onClick={openSearchPopup} className=''>
                <i className="icon-search font-bold text-[20px]" />
              </button>
              {renderWishlistIcon()}
              {renderUserInfo()}
              {renderCartItemCount()}
            </div>
          </div>
          <div className='w-full my-1'>
            <div id="search-category-wrapper" className={`search-category-wrapper mt-2 flex-grow max-w-[780px]`}>
              <div className={`searchBox`}>
                <div className="new-search-component">
                  <div className="input-group searchBar">
                    <div className="input-group searchBar">
                      <HraderDeliveryAddress />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-tabs-category">
            <CustomSlider className="category-items">
              {fullCategoryList && fullCategoryList.length
                ? fullCategoryList.map(({ name, link }) => {
                  return (
                    // <div key={name} id={`category-menu-${name}`} className={`d-item  ${link === locationHref ? 'c-selected' : ''}`}>
                    <div
                      key={name}
                      id={`category-menu-${name}`}
                      className={`category-block ${new URL(link).pathname === locationHref ? 'c-selected' : ''}`}
                    >
                      <Link prefetch={false} href={`${handleNavigate(link)}`}>
                        <a className="cursor-pointer no-text-decoration">
                          <div className="category-icon-wrap d-flex align-items-center justify-content-center" />
                          <div className="category-name">
                            <h4 className="mb-0 fs-12 semibold text-center">{name}</h4>
                          </div>
                        </a>
                      </Link>
                    </div>
                  );
                })
                : null}
            </CustomSlider>
          </div>
          <div>{children}</div>

          {is_happy_hours_enable ? (
            <div className="timer-sec s-nav-hh d-lg-none">
              <HappyHours className="class" showText={false} />
            </div>
          ) : null}
          <div />
        </div>
        <CSSTransition appear in={showAllCartProductsWidget} mountOnEnter timeout={300} classNames="slideup-cart" unmountOnExit>
          <CartProductsWidget goToCart={goToCart} setShowAllCartProductsWidget={setShowAllCartProductsWidget} />
        </CSSTransition>

        <CSSTransition appear in={showUserDropdown} mountOnEnter timeout={300} classNames="slideup-cart" unmountOnExit>
          <UserDropdown />
        </CSSTransition>
      </div>
    </CSSTransition>
  );
}
